import { i18n } from "../../i18n";

const dateCustom = (date: Date): string => {
  const language = i18n.language;
  const listDay =
    language === "id"
      ? ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"]
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
  const listMonth =
    language === "id"
      ? [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember",
        ]
      : [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
  return (
    listDay[date.getDay()] +
    ", " +
    date.getDate() +
    " " +
    listMonth[date.getMonth()]
  );
};

export default dateCustom;
